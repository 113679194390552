import React, { useState } from 'react';
import Modal from 'react-modal';
import '../styler.css';
import '../global.css';
import img1 from '../assets/Images/c1.png';
import img2 from '../assets/Images/c2.png';
import img3 from '../assets/Images/c3.png';
import img4 from '../assets/Images/c4.png';
import img5 from '../assets/Images/c5.png';
import img6 from '../assets/Images/c6.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const images = [img1, img2, img3, img4, img5, img6];

Modal.setAppElement('#root'); // Necessário para acessibilidade

const Home = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        nome: '',
        cpf: '',
        dataNascimento: '',
        telefone: '',
        cep: '',
        endereco: '',
        numero: '', // Novo campo para número da casa
        valorContaLuz: '',
        tipoInstalacao: '',
        projetoSobra: 'não'
    });

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const formatCPF = (value) => {
        value = value.replace(/\D/g, ''); // Remove tudo que não é dígito
        value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o primeiro ponto
        value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca o segundo ponto
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca o hífen
        return value;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'cpf') {
            setFormData({ ...formData, [name]: formatCPF(value) });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleCepChange = async (e) => {
        const cep = e.target.value;
        setFormData((prevFormData) => ({ ...prevFormData, cep }));

        if (cep.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                const data = await response.json();
                if (!data.erro) {
                    setFormData((prevFormData) => ({ ...prevFormData, endereco: `${data.logradouro}, ${data.bairro}, ${data.localidade} - ${data.uf}` }));
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP", error);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const mailtoLink = `mailto:novageral@daqui.club?subject=Contato%20Placas%20Solares&body=Nome: ${formData.nome}%0D%0ACPF: ${formData.cpf}%0D%0AData de Nascimento: ${formData.dataNascimento}%0D%0ATelefone: ${formData.telefone}%0D%0ACEP: ${formData.cep}%0D%0AEndereço: ${formData.endereco}, ${formData.numero}%0D%0AValor da Última Conta de Luz: ${formData.valorContaLuz}%0D%0ATipo de Instalação: ${formData.tipoInstalacao}%0D%0AProjeto com Sobra de Energia: ${formData.projetoSobra}`;
        window.open(mailtoLink, '_blank');
        closeModal();
    };

    return (
        <div className="home-container">
            <section className="contact-section">
                <button onClick={openModal} className="email-button">
                    <FontAwesomeIcon icon={faEnvelope} /> Fale Conosco via Email
                </button>
            </section>
            {images.map((image, index) => (
                <div key={index} style={{ cursor: 'pointer' }} onClick={openModal}>
                    <img src={image} alt={`Projeto ${index + 1}`} style={{ width: '70%', height: 'auto' }} />
                </div>
            ))}
            <section className="contact-section">
                <h2>Faça um orçamento</h2>
                <button onClick={openModal} className="email-button">
                    <FontAwesomeIcon icon={faEnvelope} /> Fale Conosco via Email
                </button>
            </section>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modal" overlayClassName="modal-overlay">
                <h2>Preencha as informações</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Nome Completo:
                        <input type="text" name="nome" value={formData.nome} onChange={handleInputChange} required />
                    </label>
                    <label>
                        CPF:
                        <input type="text" name="cpf" value={formData.cpf} onChange={handleInputChange} required />
                    </label>
                    <label>
                        Data de Nascimento:
                        <input type="date" name="dataNascimento" value={formData.dataNascimento} onChange={handleInputChange} required />
                    </label>
                    <label>
                        Telefone:
                        <input type="tel" name="telefone" value={formData.telefone} onChange={handleInputChange} required />
                    </label>
                    <label>
                        CEP:
                        <input type="text" name="cep" value={formData.cep} onChange={handleCepChange} onBlur={handleCepChange} required />
                    </label>
                    <label>
                        Endereço:
                        <input type="text" name="endereco" value={formData.endereco} onChange={handleInputChange} required readOnly />
                    </label>
                    <label>
                        Número:
                        <input type="text" name="numero" value={formData.numero} onChange={handleInputChange} required />
                    </label>
                    <label>
                        Valor da Última Conta de Luz:
                        <input type="number" name="valorContaLuz" value={formData.valorContaLuz} onChange={handleInputChange} required />
                    </label>
                    <label>
                        Tipo de Instalação:
                        <select name="tipoInstalacao" value={formData.tipoInstalacao} onChange={handleInputChange} required>
                            <option value="">Selecione...</option>
                            <option value="solo">Solo</option>
                            <option value="telha-colonial">Telha Colonial</option>
                            <option value="telha-fibrocimento">Telha de Fibrocimento</option>
                            <option value="telha-metalica">Telha Metálica</option>
                        </select>
                    </label>
                    <label>
                        Projeto com Sobra de Energia:
                        <select name="projetoSobra" value={formData.projetoSobra} onChange={handleInputChange}>
                            <option value="sim">Sim</option>
                            <option value="não">Não</option>
                        </select>
                    </label>
                    <button type="submit">Enviar</button>
                    <button type="button" onClick={closeModal}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

export default Home;
